.banner {
  z-index: 100;
  position: relative;
  display: grid;

  margin: 0;

  .banner-row {
    overflow: hidden;
    // background-color: bisque;
  display: flex;
  align-items: left;
  justify-items: left;
    @media (max-width: $layout-breakpoint-medium) {
      &:first-child {
        margin-top: 48px;
      }
    }
    &.center {
      justify-content: right;
      text-align: right;
      margin-top: 2rem;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: right;
    }
    .row-title,
    .row-letter {
      font-size: 20rem;
      font-weight: 500;
      text-align: right;
      justify-items: end;
      
      position: relative;
      letter-spacing: -0.8rem;
      display: inline-block;
      white-space: nowrap;
      @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 13rem;
      }
    }
    .row-col {
      width: 60%;
      display: grid;
      margin-bottom: 4rem;
      align-content: left;
      justify-content: left;
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-end;
      }
      .row-message {
        display: grid;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 50%;
        margin-right: 2rem;
        text-align: center;
        margin-left:auto;
        background-color: beige;
      

      }
    }
    .scroll {
      height: 60px;
      width: 60px;
      border-radius: 40%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 1%;
      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }
      @media (max-width: 1240px) {
        display: none;
      }
      span {
        display: grid;
        font-size: 15px;
        font-weight: 600;
        margin: 4px 0;
        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }
}

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    top: -100px;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    z-index: -100;
    @media (max-width: $layout-breakpoint-xsmall) {
      top: -56px;
    }
    img {
      width: 100%;
      max-width: 100%;
      min-height: 1000px;
    }
  }
}
.Test{
  position: relative;
  text-align: center;
  float: right;
}
