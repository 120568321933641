.container{
    .myswiper{
        width: 100% !important;
        height: 20vh;
        .swiperslide{ 
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            text-align: center;
        }    
    }
    }
    .swiperslide{

    }
    .swiper-button-next{
        color: wheat !important;
    }
}
